<template>
    <div class="notice">
        <full-scroll></full-scroll>
        <div
            class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            "
        >
            <div class="flex flex-pack-center flex-1">
                <img
                    src="@/assets/national_emblem.png"
                    alt=""
                    class="msg-national m_r20"
                />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">最新通知</div>
            </div>
            <div class="flex-1 flex-end flex" @click="backHome">
                <div class="my_text_center">
                    <img
                        src="@/assets/images/backhome-new.png"
                        alt=""
                        class="msg-backhome m_b10"
                    />
                    <p>返回首页</p>
                </div>
            </div>
        </div>

        <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">
            <div class="notice-box">
                <div class="m_b5 color_FF" v-if="firstNewAdverList.length > 0">
                    <p class="font-size28 m_b50 my_text_center font-blod">
                        {{ firstNewAdverList[0].title }}
                    </p>
                    <p class="my_text_center">
                        <span
                            class="notice-first-check"
                            @click="goToDetail(firstNewAdverList[0])"
                            >查看详情</span
                        >
                    </p>
                </div>

                <div class="notice-box-list" id="notice-box-list">
                    <ul>
                        <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="onLoad"
                        >
                            <li
                                class="notice-list flex"
                                v-for="(item, id) in newAdverList"
                                :key="id"
                            >
                                <div>
                                    <img
                                        :src="item.cover"
                                        alt=""
                                        class="notice-list-pic"
                                    />
                                </div>
                                <div
                                    class="
                                        flex-1 flex flex-v flex-justify-center
                                        m_r20
                                    "
                                >
                                    <p class="color_FF m_b20">
                                        <span class="font-size24 font-blod">{{
                                            item.title
                                        }}</span>
                                    </p>
                                    <p class="color_FF">
                                        <span class="font-size18">{{
                                            item.releaseTime
                                        }}</span>
                                    </p>
                                </div>
                                <div
                                    class="
                                        flex
                                        flex-justify-center
                                        flex-pack-center
                                        color_FF
                                    "
                                    @click="goToDetail(item)"
                                >
                                    <span class="notice-list-desc">
                                        查看详情
                                    </span>
                                </div>
                            </li>
                        </van-list>
                    </ul>
                </div>
            </div>
        </div>

        <div
            class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            "
        >
            <div class="my_text_center" @click="goBack">
                <img
                    src="@/assets/images/return-new.png"
                    alt=""
                    class="msg-return m_b10"
                />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF">
            </div>

            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { dateTimeFormat } from "@/request/until.js";
import { getNewsList } from "@/request/api.js";
import fullScroll from "@/components/fullScroll.vue";

export default {
    components: { fullScroll },
    data() {
        return {
            loading: false,
            finished: false,
            todayMtime: "",
            todayTime: "",
            newAdverList: [],
            firstNewAdverList: [],
            pageNum: 1,
            pageSize: 8,
            totalPage: null,
            scrollTop: 0,
        };
    },
    created() {
        this.getTime();
    },
    activated() {
        document.getElementById("notice-box-list").scrollTop = this.scrollTop; // 设置滚动条位置
    },

    mounted() {
        this.getAdverList();
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },
    methods: {
        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },

        getAdverList() {
            getNewsList({
                tid: 4,
                status: 1,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }).then((res) => {
                this.loading = false;
                let data = res.data.list;
                if (data.length == 0) {
                    this.finished = true;
                } else {
                    if (this.pageNum == res.data.totalPage) {
                        this.finished = true;
                        this.totalPage = res.data.totalPage;
                        this.firstNewAdverList = data.splice(0, 1);
                        data.forEach((item) => {
                            item.releaseTime = item.releaseTime.split(" ")[0];
                        });
                        this.newAdverList = data;
                    } else {
                        this.finished = false;
                        this.totalPage = res.data.totalPage;
                        this.firstNewAdverList = data.splice(0, 1);
                        data.forEach((item) => {
                            item.releaseTime = item.releaseTime.split(" ")[0];
                        });

                        this.newAdverList = data;
                    }
                }
            });
        },

        //返回首页
        backHome() {
            this.$router.push("/");
        },
        goBack() {
            this.$router.back();
        },

        //跳转详情
        goToDetail(data) {
            this.scrollTop =
                document.getElementById("notice-box-list").scrollTop;
            let Data = JSON.stringify(data);
            this.$router.push({
                name: "supervisedetail",
                params: { data: encodeURIComponent(Data), type: 2 },
            });
        },

        onLoad() {
            if (this.pageNum <= this.totalPage) {
                this.loading = true;
                this.pageNum++;
                getNewsList({
                    tid: 4,
                    status: 1,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                }).then((res) => {
                    this.loading = false;
                    let data = res.data.list;
                    if (data.length == 0) {
                        this.finished = true;
                    } else {
                        if (this.pageNum == res.data.totalPage) {
                            this.finished = true;
                        } else {
                            this.finished = false;
                        }
                        data.forEach((item) => {
                            item.createTime = item.createTime.split(" ")[0];
                        });

                        this.newAdverList.push(...data);
                    }
                });
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        if (to.path === "/supervisedetail") {
            from.meta.keepAlive = true;
        } else {
            from.meta.keepAlive = false;
        }
        next();
    },
};
</script>
